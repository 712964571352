import React, { useState } from 'react';

const SentryTest = ({ error }) => {
  const [arrayVar2, setArrayVar2] = useState([]);

  const klik = () => {
    setArrayVar2('tes');
  };
  return (
    <div>
      {
        arrayVar2.map((a,i) => (
          <p key={i}>{a}</p>
        ))
      }
      <button
        onClick={klik}
        className='p-2 bg-color-danger-500 text-white m-5'>
        Klik Tes
      </button>
    </div>
  );
};

export default SentryTest;