import { Typography } from "./Typography"
import moment from 'moment'
import { getNotificationRead } from "../../api/profileNotication"
import { useQuery } from "react-query"

const NotificationItem = ({ item, refetch }) => {
  const clickOne = useQuery({
    queryKey: 'getNotificationRead',
    queryFn: () => getNotificationRead(item.id),
    onSuccess: (data) => {
      refetch()
    },
    staleTime: Infinity,
    enabled: false
  })

  const handleClickNotification = () => {
    clickOne.refetch()
  }
  return (
    <div className={`py-[10px] px-5 ${!item.is_read && 'bg-color-primary-50'} flex flex-col cursor-pointer`} onClick={handleClickNotification}>
      <Typography
        text={moment(item.created_at).format('DD MMM YYYY')}
        size="xs"
        color="secondary"
      />
      <Typography
        text={item.title}
        size="sm"
        weight="semibold"
        color="base"
      />
      <Typography
        text={item.description}
        size="sm"
        color="secondary"
      />
    </div>
  )
}

export default NotificationItem