import { useNavigate } from "react-router-dom"
import { ChevronDownIcon, UserCircleIcon } from "../../assets/icons"
import HeaderDropdown from "../atoms/HeaderDropdown"
import { Typography } from "../atoms/Typography"
import { useMutation } from "react-query"
import { postLogout } from "../../api/auth"
import { store } from "../../redux/store"
import { loggedOut } from "../../redux/logged/loggedAction"
import { toastError, toastSuccess } from "../atoms/CustomToast"
import { useState } from "react"

const HeaderProfile = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleClick = (path) => {
    navigate(path)
    setOpen(false)
  }
  const user = JSON.parse(localStorage.getItem('user'))
  // const user = localStorage.getItem('user')
  // console.log('ssd', JSON.parse(user))
  const handlePostLogout = useMutation({
    mutationFn: postLogout,
    onSuccess: () => {
      toastSuccess('Anda telah logout')
    },
    onError: (data) => {
      const errorMessage = data?.response?.data?.errors[0]?.message
      toastError(errorMessage)
    },
    onSettled: () => {
      store.dispatch(loggedOut())
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('user')
      localStorage.removeItem('is_prem')
      localStorage.removeItem('data_user')
      localStorage.removeItem('data_bank')
    }
  })
  const handleSignOut = () => {
    handlePostLogout.mutate()
  }
  return (
    <HeaderDropdown
      position="right"
      open={open}
      setOpen={setOpen}
      button={
        <div className="flex items-center space-x-1 text-color-neutral-500">
          <UserCircleIcon />
          <div className="flex items-center">
            <Typography
              text={user?.full_name}
              size="sm"
              color="base"
              className="hidden lg:inline"
            />
            <ChevronDownIcon />
          </div>
        </div>
      }
    >
      <div className="w-[240px] flex flex-col">
        {
          buttonList.map((button, index) => (
            <button key={`profile-dropdown-${index + 1}`} className="px-4 py-3 text-sm text-color-neutral-700 hover:bg-color-neutral-200 w-full text-left" onClick={() => handleClick(button.path)}>
              {button.label}
            </button>
          ))
        }
        <button className="px-4 py-3 text-md text-color-neutral-700 hover:bg-color-neutral-200 w-full text-left border-t border-color-neutral-200" onClick={handleSignOut}>
          Keluar
        </button>
      </div>

    </HeaderDropdown>
  )
}

export default HeaderProfile

const buttonList = [
  {
    label: 'Pengaturan',
    path: '/profile/setting'
  },
  {
    label: 'Transaksi',
    path: '/profile/transaction'
  },
  {
    label: 'Favorit',
    path: '/profile/favorite'
  },
  {
    label: 'NIPL',
    path: '/profile/nipl'
  },
  {
    label: 'Proxy Bid',
    path: '/profile/proxy-bid'
  },
]