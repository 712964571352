import RouterApp from './routes';
import { QueryClientProvider, QueryClient } from 'react-query'
import 'moment/locale/id'
import { useToasterStore } from 'react-hot-toast';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as Sentry from "@sentry/react";

// const twentyFourHoursInMs = 1000 * 60 * 60 * 24
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})


function App() {
  const { toasts } = useToasterStore()

  const TOAST_LIMIT = 3

  // Enforce Limit
  useEffect(() => {
    toasts
      .filter(t => t.visible) // Only consider visible toasts
      .filter((item, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach(t => toast.dismiss(t.id)) // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterApp />
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);
